import { useMemo } from "react";
import useHamburgerNav from "src/hooks/useHamburgerNav";
import useHardwareBackListener from "src/hooks/useHardwareBackListener";
import useKeyboardListener from "src/hooks/useKeyboardListener";
import { KeyboardListenerCallbackMap } from "src/hooks/useKeyboardListener/types";
import useNoScroll from "src/hooks/useNoScroll";
import useCloseFirstModal from "src/modals/hooks/useCloseFirstModal";
import useInsideModal from "src/modals/hooks/useInsideModal";
import { IndividualModalConfig } from "src/modals/types";
import useRemoveModalOnUnmount from "./hooks/useRemoveModalOnUnmount";

/**
 * This is a tracker component that checks to ensure modals have been opened successfully
 *
 * Also handles modal-specific things:
 * - disable scroll
 * - remove hamburger navigation menu
 * - escape key and android hardware back listeners
 */
const ModalOpenDataManager = ({
	name,
	config,
}: {
	name: string;
	config: IndividualModalConfig<any, any>;
}) => {
	useHamburgerNav(false);
	useNoScroll();
	useRemoveModalOnUnmount(name);

	// Escape listener for web
	const { closeFirstModal } = useCloseFirstModal({ name, config });
	const keyboardListener = useMemo<KeyboardListenerCallbackMap>(
		() => ({
			Escape: () => closeFirstModal(),
		}),
		[closeFirstModal],
	);
	useKeyboardListener(keyboardListener);

	// Hardware back nav listener for Android
	// Note: since the back listener blocks other (previously created) listeners,
	// we can just call closeSelf here.
	const { closeSelf } = useInsideModal();
	useHardwareBackListener(closeSelf, true);
	return null;
};
export default ModalOpenDataManager;
