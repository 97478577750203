import Pino, { LoggerOptions } from "pino";
import { BRANCH } from "src/config";

const getLog = (options: Omit<LoggerOptions, "redact"> = {}) =>
	Pino({
		name: BRANCH,
		formatters: {
			level: (label) => ({ level: label }),
		},
		...options,
	});

export default getLog;
