import { LinearGradient } from "expo-linear-gradient";
import { useEffect, useMemo } from "react";
import { View } from "react-native";
import Animated, {
	cancelAnimation,
	Easing,
	useAnimatedStyle,
	useSharedValue,
	withDelay,
	withRepeat,
	withSequence,
	withTiming,
} from "react-native-reanimated";
import useStyle from "src/hooks/useStyle";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { color } from "src/styles";
import { ShimmerProps } from "./types";

const ShimmerWidthPercent = 120;
/**
 * Extra margin to account for the diagonal
 * I'm sure I could do some trig for this, but it's easy to eyeball
 * If you want to test this, remove the overflow hidden
 */
const DiagonalMargin = 1.1;
const DiagonalMarginWidthMultiplier = 2 * DiagonalMargin - 1;
const Angle = 8;

const Shimmer = ({
	style,
	enabled = true,
	colorVariant = "Regular",
	delay = 800,
}: ShimmerProps) => {
	const progress = useSharedValue(0);
	const enabledSV = useSharedValue(enabled);
	useEffect(() => {
		enabledSV.value = enabled;
		if (enabled) {
			progress.value = withRepeat(
				withDelay(
					delay,
					withSequence(
						withTiming(1, {
							duration: 700,
							easing: Easing.linear,
						}),
						withTiming(0, { duration: 0 }),
						withTiming(1, {
							duration: 700,
							easing: Easing.linear,
						}),
						withTiming(0, { duration: 0 }),
					),
				),
				Infinity,
			);
		} else {
			cancelAnimation(progress);
		}
	}, [delay, enabled, enabledSV, progress]);
	const { theme } = useStyle();
	const { baseColor, shimmerColor } = useMemo<{
		baseColor: string;
		shimmerColor: string;
	}>(() => {
		switch (colorVariant) {
			case "Regular":
				return {
					baseColor: "#ebebeb",
					shimmerColor: "#e3e3e3",
				};
			case "Modal":
				return {
					baseColor: color.BackgroundModal[theme],
					shimmerColor: color.BackgroundModalShimmer[theme],
				};
			case "Card":
				return {
					baseColor: color.BackgroundCard[theme],
					shimmerColor: color.BackgroundCardShimmer[theme],
				};
			case "Pill":
				return {
					baseColor: color.PillBackground[theme],
					shimmerColor: color.PillBackgroundShimmer[theme],
				};
			default:
				return enumNever(colorVariant);
		}
	}, [colorVariant, theme]);

	const width = useSharedValue(0);
	const animatedStyle = useAnimatedStyle(
		() => ({
			opacity: withTiming(enabledSV.value ? 1 : 0, {
				duration: 200,
			}),
			transform: [
				{
					rotate: `${Angle}deg`,
				},
				{
					translateX:
						progress.value *
						(1 + (DiagonalMarginWidthMultiplier * ShimmerWidthPercent) / 100) *
						width.value,
				},
			],
		}),
		[enabledSV, progress, width],
	);

	return (
		<View
			style={[
				{
					backgroundColor: baseColor,
					overflow: "hidden",
				},
				style,
			]}
		>
			<Animated.View
				style={[{ flex: 1 }, animatedStyle]}
				onLayout={(event) => {
					width.value = event.nativeEvent.layout.width;
				}}
			>
				<LinearGradient
					pointerEvents="none"
					colors={[baseColor, shimmerColor, baseColor]}
					start={{ x: 0, y: 0 }}
					end={{ x: 1, y: 0 }}
					style={[
						{
							left: `${-DiagonalMargin * ShimmerWidthPercent}%`,
							width: `${ShimmerWidthPercent}%`,
							top: "-50%",
							height: "200%",
						},
					]}
				/>
			</Animated.View>
		</View>
	);
};
export default Shimmer;
