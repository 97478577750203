import { useEffect } from "react";
import { IndividualModalConfig } from "../types";
import useModalState from "./useModalState";

const useModalRegistration = (name: string, config: IndividualModalConfig<any, any>) => {
	useEffect(() => {
		const { registerModalConfig, unregisterModalConfig } = useModalState.getState();
		const { alreadyRegistered } = registerModalConfig(name, config);
		return () => {
			if (!alreadyRegistered) {
				unregisterModalConfig(name);
			}
		};
	}, [config, name]);
};
export default useModalRegistration;
