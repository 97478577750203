import { useMemo } from "react";
import { MOBILE_CUTOFF } from "src/stores/useDeviceStore";
import { ImageProps } from "../types";

const useParseSizes = (sizes: ImageProps["sizes"]) => {
	return useMemo(() => {
		if (!sizes) return undefined;
		if (typeof sizes === "string") return sizes;
		const sizesArr: string[] = [];
		for (const [key, value] of Object.entries(sizes)) {
			if (key === "mobile") {
				sizesArr.push(`(max-width: ${MOBILE_CUTOFF}px) ${value}`);
			}
			if (key === "desktop") {
				// Added at the end
				continue;
			}
			if (key.startsWith("width:")) {
				const width = key.slice(7, -2);
				sizesArr.push(`(max-width: ${width}px) ${value}`);
			}
			if (key.startsWith("height:")) {
				const height = key.slice(8, -2);
				sizesArr.push(`(max-height: ${height}px) ${value}`);
			}
		}
		if (sizes.desktop) {
			sizesArr.push(sizes.desktop);
		} else {
			sizesArr.push("100vw");
		}
		return sizesArr.join(", ");
	}, [sizes]);
};
export default useParseSizes;
