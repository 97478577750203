import {
	addBreadcrumb,
	captureException,
	captureMessage,
	flush,
	setContext,
	setExtra,
	setUser,
} from "@sentry/browser";
import type { SentryClient } from "./noop";

const sentry: SentryClient = {
	addBreadcrumb,
	captureException: captureException as any,
	captureMessage: captureMessage as any,
	flush,
	setContext,
	setExtra,
	setUser,
};

export default sentry;
