import { useMemo } from "react";
import { ImageDim } from "../types";

const useParseImageDim = (dim: ImageDim) => {
	return useMemo(() => {
		if (dim === "auto") {
			return {
				width: undefined,
				height: undefined,
				fill: true,
			} as const;
		}
		if (dim.width && dim.height) {
			return {
				width: dim.width,
				height: dim.height,
				fill: false,
			};
		}
		if (dim.width && dim.aspectRatio) {
			return {
				width: dim.width,
				height: dim.width / dim.aspectRatio,
				fill: false,
			};
		}
		if (dim.height && dim.aspectRatio) {
			return {
				width: dim.height * dim.aspectRatio,
				height: dim.height,
				fill: false,
			};
		}
		throw new Error("Invalid dimensions");
	}, [dim]);
};
export default useParseImageDim;
