/**
 * WARNING: DO NOT EDIT UNLESS YOU ARE IN THE ROOT SHARED FOLDER -- NOT A SUBDIRECTORY
 */

import { DAY_TO_MS, HOUR_TO_MS, MIN_TO_MS } from "../constants/constants";
import { Timestamp_MS, Timestamp_S } from "../types/general";

export const getCurTime_MS = (): Timestamp_MS => {
	return Date.now();
};

export const getCurTime_S = (): Timestamp_S => {
	return Math.floor(Date.now() / 1000);
};

const pad = (num: string): string => {
	return num.length === 1 ? `0${num}` : num;
};

// I don't wanna hear shit about how this is janky
// Frontend and backend behave differently here for some reason
// We also need a vanilla JS solution since we don't want to force a library
export const getMostRecentPastMidnight = (timezone: string = "America/New_York"): Date => {
	const tzMidnight = new Date().toLocaleString("en-US", {
		timeZone: timezone,
		timeZoneName: "longOffset",
	});
	const split = tzMidnight.split(" ");
	// Chopping off last comma
	const dateSplit = split[0]!.slice(0, split[0]!.length - 1).split("/");
	const timezoneOffset = split[split.length - 1]!.slice(3); // Slicing out the GMT
	const dateStr = `${dateSplit[2]}-${pad(dateSplit[0]!)}-${pad(
		dateSplit[1]!,
	)}T00:00:00.000${timezoneOffset}`;
	const midnightInTz = new Date(dateStr);
	return midnightInTz;
};

export const getMostRecentPastReleaseTime = (): Date => {
	const midnight = getMostRecentPastMidnight("America/New_York");
	const release = new Date(midnight.getTime() + 21 * HOUR_TO_MS);
	if (release > new Date()) {
		release.setDate(release.getDate() - 1);
	}
	return release;
};
export const getCurReleaseYyyyMmDd = (): string => {
	// 12 should put us pretty safely in the correct day
	const date = new Date(getMostRecentPastReleaseTime().getTime() + 4 * HOUR_TO_MS);
	return date.toISOString().slice(0, 10);
};
export const getMostRecentReleaseTimeWithMargin = (margin: Timestamp_MS = 10 * MIN_TO_MS): Date => {
	const midnight = getMostRecentPastMidnight("America/New_York");
	const release = new Date(midnight.getTime() + 21 * HOUR_TO_MS - margin);
	if (release > new Date()) {
		release.setDate(release.getDate() - 1);
	}
	return release;
};
export const getMostRecentReleaseWithMarginYyyyMmDd = (margin?: Timestamp_MS): string => {
	const date = getMostRecentReleaseTimeWithMargin(margin).getTime() + 4 * HOUR_TO_MS;
	return new Date(date).toISOString().slice(0, 10);
};

export const getMidnightTonight = (timezone: string = "America/New_York"): Date =>
	new Date(getMostRecentPastMidnight(timezone).getTime() + DAY_TO_MS);

// The Apple Health library doesn't give correct ISO strings, since the timezone is missing a colon.
// This function handles both correct and pseudo ISO strings and returns the timezone offset wrt UTC in minutes
export const getTimezoneOffsetFromPseudoIso = (pseudoIso: string) => {
	const tail = pseudoIso.slice(-6);
	const timezoneOffset = parseInt(pseudoIso.slice(-5).replace(":", "").replace("-", ""));
	const timezoneOffsetHours = Math.floor(timezoneOffset / 100);
	const timezoneOffsetMinutes = timezoneOffset % 100;
	const timezoneOffsetTotalMinutes = timezoneOffsetHours * 60 + timezoneOffsetMinutes;
	if (tail.includes("-")) {
		return -timezoneOffsetTotalMinutes;
	}
	return timezoneOffsetTotalMinutes;
};

export const getTodayYyyyMmDd = (): string => {
	const date = new Date();
	return date.toISOString().slice(0, 10);
};

export const prettyDate = (date: Date): string =>
	("" + (date.getMonth() + 1)).slice(-2) +
	"/" +
	("" + date.getDate()).slice(-2) +
	"/" +
	date.getFullYear();
export const shortFormatDate = (date: Date): string =>
	(date.getMonth() + 1).toString().padStart(2, "0") +
	"/" +
	date.getDate().toString().padStart(2, "0") +
	"/" +
	date.getFullYear();
