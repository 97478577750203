import { useEffect, useRef } from "react";
import { KeyboardListenerCallbackMap } from "./types";

const useKeyboardListener = (callbackMap: KeyboardListenerCallbackMap) => {
	const curCallbackMap = useRef(callbackMap);
	// There's no point in using a useEffect here since calbackMap isn't designed to be memoized
	curCallbackMap.current = callbackMap;

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (typeof curCallbackMap.current === "function") {
				curCallbackMap.current(e);
			} else {
				const callback = curCallbackMap.current[e.key];
				if (callback) {
					callback(e);
				}
			}
		};
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []);
};
export default useKeyboardListener;
