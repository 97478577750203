"use client";
import { useRef } from "react";
import { ScaledSize } from "react-native";
import { create } from "zustand";
import { UseDimProps } from "./types";

const useDimStore = create<{
	width: number;
	height: number;
	fontScale: number;
	setNewDim: (dim: { width: number; height: number }) => void;
}>((set) =>
	typeof window === "undefined"
		? {
				width: 1600,
				height: 900,
				fontScale: 1,
				setNewDim: (dim) => set(dim),
			}
		: {
				width: window.innerWidth,
				height: window.innerHeight,
				fontScale: 1,
				setNewDim: (dim) => set(dim),
			},
);

if (typeof window !== "undefined") {
	window.addEventListener("resize", () => {
		useDimStore.setState({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	});
}

const useDim = ({ staticDim }: UseDimProps = {}): ScaledSize => {
	const stableWidth = useDimStore((state) => state.width);
	const stableHeight = useDimStore((state) => state.height);
	const stableFontScale = useDimStore((state) => state.fontScale);

	const { staticWidth, staticHeight, staticFontScale } = useRef({
		staticWidth: stableWidth,
		staticHeight: stableHeight,
		staticFontScale: stableFontScale,
	}).current;

	if (staticDim) {
		return {
			width: staticWidth,
			height: staticHeight,
			fontScale: staticFontScale,
			scale: 1,
		};
	}
	return {
		width: stableWidth,
		height: stableHeight,
		fontScale: stableFontScale,
		scale: 1,
	};
};
export default useDim;
