import { useEffect } from "react";
import log from "src/helpers/log";
import { useModalContext } from "../contexts/ModalContext";
import { ModalType } from "../types";
import useModalState from "./useModalState";

const useInsideModal = <T extends ModalType[string]>() => {
	const { name, closeSelf } = useModalContext();
	if (!name) throw new Error("useInsideModal must be used inside a modal");

	const props = useModalState((state) => state.propsLookup[name]);
	const prefetchPromise = useModalState((state) => state.prefetchPromiseLookup[name]);

	useEffect(() => {
		if (!props) {
			log.error("Missing props object in useInsideModal.");
		}
	}, [props]);

	return {
		closeSelf,
		props: props as T["props"],
		name,
		prefetchPromise: prefetchPromise as T["prefetch"] extends Function
			? ReturnType<T["prefetch"]>
			: never,
	};
};
export default useInsideModal;
