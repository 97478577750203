import { useEffect } from "react";

const useNoScroll = (enabled: boolean = true) => {
	useEffect(() => {
		if (enabled) {
			const hasNoScroll = document.body.classList.contains("no-scroll");
			if (hasNoScroll) return;
			document.body.classList.add("no-scroll");
			return () => {
				document.body.classList.remove("no-scroll");
			};
		}
		return;
	}, [enabled]);
};
export default useNoScroll;
