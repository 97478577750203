import { useMemo } from "react";
import { DimensionValue } from "react-native";
import Shimmer from "src/components/Shimmer";
import native from "src/constants/native";
import useDevice from "src/hooks/useDevice";

type SizeVariant = "Small" | "Medium" | "Large" | "Massive";

type ModalDimensionsHook = () => ModalDimensionResult;
type ModalDimensionResult = {
	width: DimensionValue;
	height: DimensionValue;
	minWidth?: DimensionValue;
	minHeight?: DimensionValue;
	maxWidth?: DimensionValue;
	maxHeight?: DimensionValue;
};

const createDimensionsHook = ({
	sizeVariant,
}: {
	sizeVariant: SizeVariant;
}): ModalDimensionsHook => {
	switch (sizeVariant) {
		case "Small":
			return () => {
				const { device } = useDevice();
				return useMemo<ModalDimensionResult>(() => {
					if (native) {
						return {
							width: "100%",
							height: "100%",
						};
					}
					if (device === "desktop") {
						return {
							width: 550,
							height: 500,
							maxWidth: "100vw" as any,
							maxHeight: "100vh" as any,
						};
					} else {
						return {
							width: "100%" as any,
							height: "100%" as any,
							maxWidth: "100dvw" as any,
							maxHeight: "100dvh" as any,
						};
					}
				}, [device]);
			};
		case "Medium":
			return () => {
				const { device } = useDevice();
				return useMemo<ModalDimensionResult>(() => {
					if (native) {
						return {
							width: "100%",
							height: "100%",
						};
					}
					if (device === "desktop") {
						return {
							width: 500,
							height: 800,
							maxWidth: "50vw" as any,
							maxHeight: "80vh" as any,
						};
					} else {
						return {
							width: "100%" as any,
							height: "100%" as any,
							maxWidth: "100dvw" as any,
							maxHeight: "100dvh" as any,
						};
					}
				}, [device]);
			};

		case "Large":
			return () => {
				const { device } = useDevice();
				return useMemo<ModalDimensionResult>(() => {
					if (native) {
						return {
							width: "100%",
							height: "100%",
						};
					}
					if (device === "desktop") {
						return {
							width: "50vw" as any,
							height: "80vh" as any,
							maxWidth: 800,
							maxHeight: 800,
						};
					} else {
						return {
							width: "100%" as any,
							height: "100%" as any,
							maxWidth: "100dvw" as any,
							maxHeight: "100dvh" as any,
						};
					}
				}, [device]);
			};
		case "Massive":
			return () => {
				const { device } = useDevice();
				return useMemo<ModalDimensionResult>(() => {
					if (native) {
						return {
							width: "100%",
							height: "100%",
						};
					}
					if (device === "desktop") {
						return {
							width: "90vw" as any,
							height: "90vh" as any,
						};
					} else {
						return {
							width: "100vw" as any,
							height: "100vh" as any,
							maxWidth: "100dvw" as any,
							maxHeight: "100dvh" as any,
						};
					}
				}, [device]);
			};
	}
};

type CreateModalHookResult<TName extends string | undefined> = TName extends undefined
	? {
			useModalDimensions: ModalDimensionsHook;
		}
	: {
			[key in `use${TName}Dimensions`]: ModalDimensionsHook;
		};
type CreateModalSkeletonResult<TName extends string | undefined> = TName extends undefined
	? {
			Skeleton: () => JSX.Element;
		}
	: {
			[key in `${TName}Skeleton`]: () => JSX.Element;
		};

const createModalSkeleton = <TName extends string | undefined>({
	name,
	sizeVariant = "Medium",
}: {
	name?: TName;
	sizeVariant?: SizeVariant;
}): CreateModalHookResult<TName> & CreateModalSkeletonResult<TName> => {
	const useModalDimensions = createDimensionsHook({ sizeVariant });
	const Skeleton = () => {
		const style = useModalDimensions();
		return <Shimmer style={style} colorVariant="Modal" />;
	};
	if (name == null) {
		// @ts-expect-error
		return {
			useModalDimensions,
			Skeleton,
		};
	} else {
		// @ts-expect-error
		return {
			[`use${name}Dimensions`]: useModalDimensions,
			[`${name}Skeleton`]: Skeleton,
		};
	}
};
export default createModalSkeleton;
