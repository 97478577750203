import { CursorObj } from "./types";

const cursor: CursorObj = {
	notAllowed: "not-allowed" as never,
	pointer: "pointer" as never,
	default: "default" as never,
	text: "text" as never,
	grab: "grab" as never,
	grabbing: "grabbing" as never,
	"nesw-resize": "nesw-resize" as never,
	"nwse-resize": "nwse-resize" as never,
	"all-scroll": "all-scroll" as never,
};
export default cursor;
