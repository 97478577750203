import { createContext, useContext } from "react";

export interface ModalContextType {
	name: string | undefined;
	closeSelf: () => void;
}

export const ModalContext = createContext<ModalContextType>({
	name: undefined,
	closeSelf: () => {},
});

/**
 * Hook to use inside modals
 */
export const useModalContext = () => {
	return useContext(ModalContext);
};
