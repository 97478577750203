import dynamic from "next/dynamic";
import Shimmer from "src/components/Shimmer";
import { LazyImporter, LazyOptions, LazyResult } from "./types";

/**
 * TODO: Replace this with a proper implementation
 */
const lazy = <P extends {}>(
	importer: LazyImporter<P>,
	{ loading, ...options }: LazyOptions<P> = {},
): LazyResult<P> => {
	let promise: ReturnType<typeof importer> | undefined = undefined;
	const cachedImporter: typeof importer = async () => {
		if (promise === undefined) {
			promise = importer();
		}
		return promise;
	};

	const dynamicResult = dynamic(cachedImporter, {
		loading:
			loading ??
			(() => (
				<Shimmer
					colorVariant="Modal"
					style={{
						flex: 1,
						minWidth: 150,
						minHeight: 150,
					}}
				/>
			)),
		...options,
	}) as LazyResult<P>;

	dynamicResult.preload = async () => {
		await cachedImporter();
	};

	return dynamicResult;
};
export default lazy;
