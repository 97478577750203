import { DISABLE_LOG } from "src/api/featureFlags";
import sentry from "src/api/sentry";
import { isDev } from "src/config";
import getLog from "./getLog";

const consoleEnabled = isDev && !DISABLE_LOG;

type ArgType = {
	msg?: string;
} & Record<string, any>;

const getHandleBreadcrumb =
	({ level, category }: { level: "info" | "warning"; category: string }) =>
	(arg: ArgType) => {
		try {
			const data = {
				...arg,
			};
			delete data.msg;
			sentry.addBreadcrumb({
				message: arg?.msg,
				category,
				level,
				data: data,
			});
		} catch (err) {
			if (consoleEnabled) {
				console.error(err);
			}
		}
		if (consoleEnabled) {
			if (level === "info") {
				console.info(arg);
			}
			if (level === "warning") {
				console.warn(arg);
			}
		}
	};

const getHandleException = (level: "error" | "fatal") => (arg: ArgType) => {
	try {
		const loggedError =
			arg?.err || new Error(typeof arg?.msg === "string" ? arg?.msg : "Logged Error");
		const extra = {
			...arg,
		};
		delete extra.err;
		sentry.captureException(loggedError, {
			extra: {
				...extra,
				message: arg?.msg,
			},
		});
	} catch (err) {
		if (consoleEnabled) {
			console.error(err);
		}
	}
	if (consoleEnabled) {
		console.error(arg);
	}
};

const log = getLog({
	level: isDev ? "debug" : "info",
	browser: {
		asObject: true,
		write: {
			fatal: getHandleException("fatal"),
			error: getHandleException("error"),
			warn: getHandleBreadcrumb({ level: "warning", category: "warn" }),
			info: getHandleBreadcrumb({ level: "info", category: "info" }),
			debug: (arg: ArgType) => {
				if (consoleEnabled) {
					console.debug(arg);
				}
			},
			trace: (arg: ArgType) => {
				if (consoleEnabled) {
					console.trace(arg);
				}
			},
		},
	},
});
export default log;
