import { useEffect } from "react";
import useModalState from "src/modals/hooks/useModalState";

const useRemoveModalOnUnmount = (name: string) => {
	useEffect(() => {
		return () => {
			const { openLookup, close } = useModalState.getState();
			const curOpenModal = openLookup[name];
			if (!curOpenModal || !curOpenModal.isOpen) {
				return;
			}
			close(name);
		};
	}, [name]);
};
export default useRemoveModalOnUnmount;
