import { makeVar } from "@apollo/client";
import { useEffect, useMemo, useRef } from "react";
import { FUNCTIONAL_HAMBURGER_HTML_ID } from "src/components/FunctionalHamburger/constants";
import { UseHamburgerNavOptions } from "./types";

const subscribers = makeVar(new Set<string>());

const subscribeToHide = (id: string, { immediate }: { immediate: boolean }) => {
	subscribers(subscribers().add(id));
	const hamburger = document.getElementById(FUNCTIONAL_HAMBURGER_HTML_ID);
	if (hamburger) {
		hamburger.style.visibility = "hidden";
		hamburger.style.opacity = "0";
		if (!immediate) {
			hamburger.style.transition = "visibility 0s 250ms, opacity 250ms linear";
		}
	}
};

const unsubscribeToHide = (id: string) => {
	subscribers().delete(id);
	subscribers(subscribers());
	if (subscribers().size < 1) {
		const hamburger = document.getElementById(FUNCTIONAL_HAMBURGER_HTML_ID);
		if (hamburger) {
			hamburger.style.visibility = "visible";
			hamburger.style.opacity = "1";
			hamburger.style.transition = "opacity 250ms linear";
		}
	}
};

const useHamburgerNav = (
	shouldShow: boolean = true,
	options: UseHamburgerNavOptions = {},
): void => {
	const id = useMemo(() => Math.random().toString(), []);
	const optionsRef = useRef(options);
	useEffect(() => {
		optionsRef.current = options;
	}, [options]);

	useEffect(() => {
		const { hideImmediate = false } = optionsRef.current;
		shouldShow
			? unsubscribeToHide(id)
			: subscribeToHide(id, {
					immediate: hideImmediate,
				});
		return () => {
			unsubscribeToHide(id);
		};
	}, [id, shouldShow]);
};

export default useHamburgerNav;
